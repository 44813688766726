import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import "./modal.scss";

function MemberListSearch({
  setModal,
  updateList,
  username,
  counselor,
  status,
  birthday,
  phone,
  sort,
  gender,
  values,
  handleChange,
  minAge,
  maxAge,
  minHeight,
  maxHeight,
  minYearIncome,
  maxYearIncome,
  minFinalStage,
  maxFinalStage,
  minMovableAsset,
  maxMovableAsset,
  minImmovableAsset,
  maxImmovableAsset,
  minParentAsset,
  maxParentAsset,
  minParentImmovableAmount,
  maxParentImmovableAmount,
  home,
  minAppearanceScore,
  maxAppearanceScore,
  minSpecsScore,
  maxSpecsScore,
  minWealthScore,
  maxWealthScore,
  smoke,
  setMinAge,
  setMaxAge,
  setMinHeight,
  setMaxHeight,
  setMinYearIncome,
  setMaxYearIncome,
  setMinFinalStage,
  setMaxFinalStage,
  setMinMovableAsset,
  setMaxMovableAsset,
  setMinImmovableAsset,
  setMaxImmovableAsset,
  setMinParentAsset,
  setMaxParentAsset,
  setMinParentImmovableAmount,
  setMaxParentImmovableAmount,
  setHome,
  setMinAppearanceScore,
  setMaxAppearanceScore,
  setMinSpecsScore,
  setMaxSpecsScore,
  setMinWealthScore,
  setMaxWealthScore,
  setSmoke,
  locationData,
  homeSido,
  setHomeSido,
  homeRegionCode,
  setHomeRegionCode,
  familyType,
  setFamilyType,
  religion,
  setReligion,
  drink,
  setDrink,
  marriage,
  setMarriage,
  homeSidoList,
  setHomeSidoList,
}) {
  const [school, setSchool] = useState([
    "",
    "고졸",
    "전문대 재학",
    "전문대 수료",
    "전문대 졸업",
    "대학 재학",
    "대학 중퇴",
    "대학 수료",
    "대학 졸업",
    "대학원 재학",
    "대학원졸업",
    "박사 과정",
    "박사 취득",
  ]);
  const [grade, setGrade] = useState(["", "A", "B", "C", "D"]);
  const [smokes, setSmokes] = useState([
    "",
    "비흡연",
    "흡연(연초)",
    "흡연(전자담배)",
    "금연예정",
    "금연중",
  ]);
  const [types, setTypes] = useState([
    "",
    "일반가정",
    "재혼가정",
    "다문화가정",
    "장애인가정",
    "이혼가정",
  ]);
  const [religions, setReligions] = useState([
    "무교",
    "천주교",
    "불교",
    "기독교",
    "기타",
  ]);
  const [marriages, setMarriages] = useState([
   "미혼", "사실혼", "무출산 재혼", "출산 재혼"
  ]);
  const [drinks, setDrinks] = useState([
    "",
    "안마셔요",
    "어쩔 수 없을 때만 마셔요",
    "가끔마셔요",
    "어느정도 즐겨요",
    "즐기는 편이예요",
  ]);

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const onReset = () => {
    setMinAge("");
    setMaxAge("");
    setMinHeight("");
    setMaxHeight("");
    setMinYearIncome("");
    setMaxYearIncome("");
    setMinFinalStage("");
    setMaxFinalStage("");
    setMinMovableAsset("");
    setMaxMovableAsset("");
    setMinImmovableAsset("");
    setMaxImmovableAsset("");
    setMinParentAsset("");
    setMaxParentAsset("");
    setMinParentImmovableAmount("");
    setMaxParentImmovableAmount("");
    setHome("");
    setMinAppearanceScore("");
    setMaxAppearanceScore("");
    setMinSpecsScore("");
    setMaxSpecsScore("");
    setMinWealthScore("");
    setMaxWealthScore("");
    setSmoke("");
    setHomeSido("");
    setHomeRegionCode("");
    setFamilyType("");
    setReligion([]);
    setDrink("");
    setMarriage([]);
    setHomeSidoList([]);
  };

  function numberToKorean(number) {
    let num = number ? number : "0";
    if (typeof number === "string") {
      num = number.replace(/\D/g, "");
    }
    const units = ["", "만", "억", "조", "경"];
    const nums = ["영", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
    let result = "";
    let unit = 10000;

    if (num === 0) {
      return "영";
    }

    for (let u of units) {
      const curr = num % unit;
      num = Math.floor(num / unit);

      if (curr !== 0) {
        let currStr = "";
        const currArr = String(curr).split("").reverse();

        for (let i = 0; i < currArr.length; i++) {
          const digit = currArr[i];
          if (digit !== "0") {
            currStr =
              nums[parseInt(digit)] +
              (i > 0 ? ["", "십", "백", "천"][i] : "") +
              currStr;
          }
        }
        result = currStr + u + result;
      }

      if (num === 0) {
        break;
      }
    }

    // "일"을 제거하는 조건을 보다 구체적으로 설정하여, "일억"과 같은 경우에는 "일"을 제거하지 않음
    return (result += "원");
  }

  const handleMoneyChange = (e, func) => {
    const value = e.target.value.replace(/\D/g, ""); // 숫자가 아닌 모든 문자 제거
    const formattedNumber = value.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // 천 단위마다 콤마 추가
    func(formattedNumber);
  };

  useEffect(() => {
    let str = "";
    locationData.forEach((item) => {
      if (item?.name === homeSido) {
        return item.list.forEach((el) => {
          if (el.regionCode === homeRegionCode) {
            str = el.regionCode;
          } else {
            return;
          }
        });
      }
    });
    setHomeRegionCode(str);
  }, [homeSido, locationData]);

  const handleCheck = (e) => {
    const { name, value } = e.target;
    if (religion?.includes(value)) {
      setReligion(religion.filter((_item) => _item !== value));
    } else {
      setReligion([...religion, value]);
    }
  };
  const handleMarrageCheck = (e) => {
    const { name, value } = e.target;
    if (marriage?.includes(value)) {
      setMarriage(marriage.filter((_item) => _item !== value));
    } else {
      setMarriage([...marriage, value]);
    }
  };
  const handleSidoListCheck = (e) => {
    const { name, value } = e.target;
    if (homeSidoList?.includes(value)) {
      setHomeSidoList(homeSidoList.filter((_item) => _item !== value));
    } else {
      setHomeSidoList([...homeSidoList, value]);
    }
    console.log("homeSidoList",homeSidoList);
  };
  return (
    <React.Fragment>
      <Container fluid className="h-100 modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5" className="h-100">
            <div className="modalOverlay" />
            <Card
              className="modalCard1 pt-3 ps-3 pe-2"
              style={{ position: "relative" }}
            >
              <h2>상세조회</h2>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3 m-0">
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최소 나이
                    </label>
                    <input
                      type="number"
                      className="js-input-mask form-control customInput"
                      name="name"
                      id="nameLabel"
                      placeholder="최소 나이를 입력해 주세요."
                      aria-label="최소 나이를 입력해 주세요."
                      value={minAge}
                      onChange={(e) => setMinAge(e.target.value)}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                </Col>
                {/* </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3"> */}
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최대 나이
                    </label>
                    <input
                      type="number"
                      className="js-input-mask form-control customInput"
                      name="name"
                      id="nameLabel"
                      placeholder="최대 나이를 입력해 주세요."
                      aria-label="최대 나이를 입력해 주세요."
                      value={maxAge}
                      onChange={(e) => setMaxAge(e.target.value)}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3 m-0">
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최소 키
                    </label>
                    <input
                      type="number"
                      className="js-input-mask form-control customInput"
                      name="name"
                      id="nameLabel"
                      placeholder="최소 키를 입력해 주세요."
                      aria-label="최소 키를 입력해 주세요."
                      value={minHeight}
                      onChange={(e) => setMinHeight(e.target.value)}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                </Col>
                {/* </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3"> */}
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최대 키
                    </label>
                    <input
                      type="number"
                      className="js-input-mask form-control customInput"
                      name="name"
                      id="nameLabel"
                      placeholder="최대 키를 입력해 주세요."
                      aria-label="최대 키를 입력해 주세요."
                      value={maxHeight}
                      onChange={(e) => setMaxHeight(e.target.value)}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3 m-0">
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최소 연봉
                    </label>
                    <input
                      type="text"
                      className="js-input-mask form-control customInput"
                      name="name"
                      id="nameLabel"
                      placeholder="최소 연봉을 입력해 주세요."
                      aria-label="최소 연봉을 입력해 주세요."
                      value={minYearIncome}
                      onChange={(e) => handleMoneyChange(e, setMinYearIncome)}
                    />
                    {minYearIncome ? (
                      <div>{numberToKorean(minYearIncome)}</div>
                    ) : null}
                  </div>
                </Col>
                {/* </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3"> */}
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최대 연봉
                    </label>
                    <input
                      type="text"
                      className="js-input-mask form-control customInput"
                      name="name"
                      id="nameLabel"
                      placeholder="최대 연봉을 입력해 주세요."
                      aria-label="최대 연봉을 입력해 주세요."
                      value={maxYearIncome}
                      onChange={(e) => handleMoneyChange(e, setMaxYearIncome)}
                    />
                    {maxYearIncome ? (
                      <div>{numberToKorean(maxYearIncome)}</div>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3 m-0">
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최소 학력
                    </label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      name="name"
                      value={minFinalStage}
                      onChange={(e) => setMinFinalStage(e.target.value)}
                    >
                      {school?.map((item, i) => {
                        if (item === "") {
                          return (
                            <option value={""} key={i}>
                              전체
                            </option>
                          );
                        } else {
                          return (
                            <option value={item} key={i}>
                              {item}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                </Col>
                {/* </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3"> */}
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최대 학력
                    </label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      name="name"
                      value={maxFinalStage}
                      onChange={(e) => setMaxFinalStage(e.target.value)}
                    >
                      {school?.map((item, i) => {
                        if (item === "") {
                          return (
                            <option value={""} key={i}>
                              전체
                            </option>
                          );
                        } else {
                          return (
                            <option value={item} key={i}>
                              {item}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3 m-0">
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최소 자산
                    </label>
                    <input
                      type="text"
                      className="js-input-mask form-control customInput"
                      name="name"
                      id="nameLabel"
                      placeholder="최소 자산을 입력해 주세요."
                      aria-label="최소 자산을 입력해 주세요."
                      value={minMovableAsset}
                      onChange={(e) => handleMoneyChange(e, setMinMovableAsset)}
                    />
                    {minMovableAsset ? (
                      <div>{numberToKorean(minMovableAsset)}</div>
                    ) : null}
                  </div>
                </Col>
                {/* </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3"> */}
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최대 자산
                    </label>
                    <input
                      type="text"
                      className="js-input-mask form-control customInput"
                      name="name"
                      id="nameLabel"
                      placeholder="최대 자산을 입력해 주세요."
                      aria-label="최대 자산을 입력해 주세요."
                      value={maxMovableAsset}
                      onChange={(e) => handleMoneyChange(e, setMaxMovableAsset)}
                    />
                    {maxMovableAsset ? (
                      <div>{numberToKorean(maxMovableAsset)}</div>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3 m-0">
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최소 비유동자산(부동산 포함)
                    </label>
                    <input
                      type="text"
                      className="js-input-mask form-control customInput"
                      name="name"
                      id="nameLabel"
                      placeholder="최소 비유동자산(부동산 포함)을 입력해 주세요."
                      aria-label="최소 비유동자산(부동산 포함)을 입력해 주세요."
                      value={minImmovableAsset}
                      onChange={(e) =>
                        handleMoneyChange(e, setMinImmovableAsset)
                      }
                    />
                    {minImmovableAsset ? (
                      <div>{numberToKorean(minImmovableAsset)}</div>
                    ) : null}
                  </div>
                </Col>
                {/* </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3"> */}
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최대 비유동자산(부동산 포함)
                    </label>
                    <input
                      type="text"
                      className="js-input-mask form-control customInput"
                      name="name"
                      id="nameLabel"
                      placeholder="최대 비유동자산(부동산 포함)을 입력해 주세요."
                      aria-label="최대 비유동자산(부동산 포함)을 입력해 주세요."
                      value={maxImmovableAsset}
                      onChange={(e) =>
                        handleMoneyChange(e, setMaxImmovableAsset)
                      }
                    />
                    {maxImmovableAsset ? (
                      <div>{numberToKorean(maxImmovableAsset)}</div>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3 m-0">
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최소 부모님 자산
                    </label>
                    <input
                      type="text"
                      className="js-input-mask form-control customInput"
                      name="name"
                      id="nameLabel"
                      placeholder="최소 부모님 자산을 입력해 주세요."
                      aria-label="최소 부모님 자산을 입력해 주세요."
                      value={minParentAsset}
                      onChange={(e) => handleMoneyChange(e, setMinParentAsset)}
                    />
                    {minParentAsset ? (
                      <div>{numberToKorean(minParentAsset)}</div>
                    ) : null}
                  </div>
                </Col>
                {/* </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3"> */}
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최대 부모님 자산
                    </label>
                    <input
                      type="text"
                      className="js-input-mask form-control customInput"
                      name="name"
                      id="nameLabel"
                      placeholder="최대 부모님 자산을 입력해 주세요."
                      aria-label="최대 부모님 자산을 입력해 주세요."
                      value={maxParentAsset}
                      onChange={(e) => handleMoneyChange(e, setMaxParentAsset)}
                    />
                    {maxParentAsset ? (
                      <div>{numberToKorean(maxParentAsset)}</div>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom px-0 pt-3 pb-3 m-0">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      가정형태
                    </label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      name="name"
                      value={familyType}
                      onChange={(e) => setFamilyType(e.target.value)}
                    >
                      {types?.map((item, i) => {
                        if (item === "") {
                          return (
                            <option value={""} key={i}>
                              전체
                            </option>
                          );
                        } else {
                          return (
                            <option value={item} key={i}>
                              {item}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                </Col>
              </Row>
              {/* <Row className="rowBorderBottom px-0 pt-3 pb-3 m-0">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label className="labelText">거주지</label>
                    <Row>
                      <Col>시/도</Col>
                    </Row>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      value={homeSido}
                      onChange={(e) => setHomeSido(e.target.value)}
                    >
                      {!homeSido ? (
                        <option>{`거주지를 선택해 주세요.`}</option>
                      ) : null}
                      {locationData.map((item, i) => {
                        return (
                          <option value={item.name} key={i}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <Row>
                      <Col>시/구/군</Col>
                    </Row>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      value={homeRegionCode}
                      onChange={(e) => setHomeRegionCode(e.target.value)}
                    >
                      {!homeRegionCode ? (
                        <option>{`거주지를 선택해 주세요.`}</option>
                      ) : null}
                      {locationData.map((item, i) => {
                        let str = homeSido;
                        if (item?.name === str) {
                          return item.list.map((el, j) => {
                            return (
                              <option value={el.regionCode} key={j}>
                                {el.name}
                              </option>
                            );
                          });
                        }
                      })}
                    </select>
                    <div>
                      <Row>
                        <Col>상세입력</Col>
                      </Row>
                      <input
                        type="text"
                        value={home}
                        className="js-input-mask form-control customInput"
                        placeholder={`거주지를 입력해 주세요.`}
                        aria-label={`거주지를 입력해 주세요.`}
                        onChange={(e) => setHome(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row> */}
              <Row className="rowBorderBottom w-100 px-0 pt-3 pb-3 m-0">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      거주시 시도
                    </label>
                    <div className="d-flex pt-2 radiowrap">
                      {locationData?.map((item, index) => (
                        <div key={index} className="d-flex mr20 mb-2">
                          <input
                            type="checkbox"
                            name="homeSidoList"
                            value={item.name}
                            id={`homeSidoList${item.name}Label`}
                            checked={homeSidoList?.includes(item.name)}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "5px",
                            }}
                            onClick={(e) => handleSidoListCheck(e)}
                          />
                          <label
                            htmlFor={`homeSidoList${item.name}Label`}
                            className="textLabel m-0"
                          >
                            {item.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3 m-0">
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최소 외모등급
                    </label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      name="name"
                      value={minAppearanceScore}
                      onChange={(e) => setMinAppearanceScore(e.target.value)}
                    >
                      {grade?.map((item, i) => {
                        if (item === "") {
                          return (
                            <option value={""} key={i}>
                              전체
                            </option>
                          );
                        } else {
                          return (
                            <option value={item} key={i}>
                              {item}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                </Col>
                {/* </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3"> */}
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최대 외모등급
                    </label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      name="name"
                      value={maxAppearanceScore}
                      onChange={(e) => setMaxAppearanceScore(e.target.value)}
                    >
                      {grade?.map((item, i) => {
                        if (item === "") {
                          return (
                            <option value={""} key={i}>
                              전체
                            </option>
                          );
                        } else {
                          return (
                            <option value={item} key={i}>
                              {item}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3 m-0">
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최소 스펙등급
                    </label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      name="name"
                      value={minSpecsScore}
                      onChange={(e) => setMinSpecsScore(e.target.value)}
                    >
                      {grade?.map((item, i) => {
                        if (item === "") {
                          return (
                            <option value={""} key={i}>
                              전체
                            </option>
                          );
                        } else {
                          return (
                            <option value={item} key={i}>
                              {item}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                </Col>
                {/* </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3"> */}
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최대 스펙등급
                    </label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      name="name"
                      value={maxSpecsScore}
                      onChange={(e) => setMaxSpecsScore(e.target.value)}
                    >
                      {grade?.map((item, i) => {
                        if (item === "") {
                          return (
                            <option value={""} key={i}>
                              전체
                            </option>
                          );
                        } else {
                          return (
                            <option value={item} key={i}>
                              {item}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3 m-0">
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최소 경제력 등급
                    </label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      name="name"
                      value={minWealthScore}
                      onChange={(e) => setMinWealthScore(e.target.value)}
                    >
                      {grade?.map((item, i) => {
                        if (item === "") {
                          return (
                            <option value={""} key={i}>
                              전체
                            </option>
                          );
                        } else {
                          return (
                            <option value={item} key={i}>
                              {item}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                </Col>
                {/* </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3"> */}
                <Col sm={6} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      최대 경제력 등급
                    </label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      name="name"
                      value={maxWealthScore}
                      onChange={(e) => setMaxWealthScore(e.target.value)}
                    >
                      {grade?.map((item, i) => {
                        if (item === "") {
                          return (
                            <option value={""} key={i}>
                              전체
                            </option>
                          );
                        } else {
                          return (
                            <option value={item} key={i}>
                              {item}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom w-100 px-0 pt-3 pb-3 m-0">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      종교
                    </label>
                    <div className="d-flex pt-2 radiowrap">
                      {religions?.map((item, index) => (
                        <div key={index} className="d-flex mr20 mb-2">
                          <input
                            type="checkbox"
                            name="religion"
                            value={item}
                            id={`religion${item}Label`}
                            checked={religion?.includes(item)}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "5px",
                            }}
                            onClick={(e) => handleCheck(e)}
                          />
                          <label
                            htmlFor={`religion${item}Label`}
                            className="textLabel m-0"
                          >
                            {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom w-100 px-0 pt-3 pb-3 m-0">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      음주여부
                    </label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      name="name"
                      value={drink}
                      onChange={(e) => setDrink(e.target.value)}
                    >
                      {drinks?.map((item, i) => {
                        if (item === "") {
                          return (
                            <option value={""} key={i}>
                              전체
                            </option>
                          );
                        } else {
                          return (
                            <option value={item} key={i}>
                              {item}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row className="w-100 px-0 pt-3 pb-3 m-0">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      흡연여부
                    </label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      name="name"
                      value={smoke}
                      onChange={(e) => setSmoke(e.target.value)}
                    >
                      {smokes?.map((item, i) => {
                        if (item === "") {
                          return (
                            <option value={""} key={i}>
                              전체
                            </option>
                          );
                        } else {
                          return (
                            <option value={item} key={i}>
                              {item}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row className="w-100 px-0 pt-3 pb-3 m-0">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      결혼유무
                    </label>
                    <div className="d-flex pt-2 radiowrap">
                      {marriages?.map((item, index) => (
                        <div key={index} className="d-flex mr20 mb-2">
                          <input
                            type="checkbox"
                            name="marriage"
                            value={item}
                            id={`marriage${item}Label`}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "5px",
                            }}
                            checked={marriage?.includes(item)}
                            onClick={(e) => handleMarrageCheck(e)}
                          />
                          <label
                            htmlFor={`marriage${item}Label`}
                            className="textLabel m-0"
                          >
                            {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row
                className="w-100 px-0 pt-3 pb-3 m-0"
                style={{
                  borderTop: "1px solid #ced4da",
                  backgroundColor: "#fff",
                  position: "sticky",
                  bottom: "0",
                }}
              >
                <Col className="text-end">
                  <Button
                    id="masterAccesBtn"
                    color="danger"
                    style={{ marginRight: "10px" }}
                    onClick={() => onReset()}
                  >
                    초기화
                  </Button>
                  <Button
                    id="masterAccesBtn"
                    color="primary"
                    onClick={() => setModal(false)}
                  >
                    확인
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default MemberListSearch;
