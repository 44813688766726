import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import "./modal.scss";
import {
  adminRequestPost,
  adminUserPut,
  requestGet,
} from "../../../apis/request";
import { set } from "lodash";

function CashPostModal({ setModal, onClickSendPurchase, userId }) {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [price, setPrice] = useState("");
  const [splitTotalPrice, setSplitTotalPrice] = useState(0);
  const [time, setTime] = useState("");
  const [amount, setAmount] = useState("");
  const [desc, setDesc] = useState("");
  
  const [installment, setInstallment] = useState(false);
  const [installmentOptions, setInstallmentOptions] = useState([]);
  const [selectedParentId, setSelectedParentId] = useState(0);
  
  // 분할결제 목록 불러오기
  // useEffect(() => {
  //   if (installment) {
  //    callInfo();
  //   }
  // }, [installment]);
  const callInfo = async () => {
    setSelectedParentId(0);
    var data = [];
      data[0] =  {
        "id": 0,
        "impUid": "",
        "merchantId": "",
        "pgName": "",
        "method": "",
        "status": "",
        "userId": 0,
        "userName": "",
        "productId": 0,
        "productName": "신규 생성",
        "productPrice": 0,
        "orderer": "",
        "phone": "",
        "email": "",
        "createdAt": ""
      };
    try {
      const params = new URLSearchParams();
      
      params.append("userId", userId);
      params.append("isSplit", true);
      const memberResponse = await requestGet(
        `/api/purchase/list?${params}`
      );
      
      if(memberResponse?.data){ 
        data = data.concat(memberResponse?.data);
      }
      setInstallmentOptions(data);
      
    } catch (error) {
      
      setInstallmentOptions(data);
      console.log(error);
    }
  };

  useEffect(() => {
    callInfo();
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const callApi = async () => {
    try {
      let body = {
        name: name,
        type: type,
        price: Number(price),
        desc: desc,
      };
      if (type === "subscribe") {
        body.days = Number(time);
      } else if (type === "joinFee" || type === "purchaseItem") {
        body.likeCoupon = Number(amount);
      }
      if(installment){
        body.splitTotalPrice = Number(splitTotalPrice);
        if(selectedParentId>0){
          body.splitMainId = selectedParentId;
        }
        
        body.isSplit = true;
      }
      const response = await adminRequestPost("/api/product/direct", body);
      onClickSendPurchase(response.data.id);
      setModal(false);
    } catch (error) {
      console.log(error);
      alert("요청 실패 하였습니다.");
      setModal(false);
    }
  };

  const onClose = async () => {
    setModal(false);
  };

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <div className="modalOverlay" />
            <Card className="modalCard py-3 ps-3 pe-2">
              <h2>상품 알림톡 보내기</h2>
              {/* 상품 이름 */}
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor="nameLabel" className="labelText">
                      상품 이름
                    </label>
                    {installment ? (
                      <select
                        className="form-select customInput"
                        name="name"
                        id="nameLabel"
                        aria-label="상품 이름을 선택해 주세요."
                        value={name}
                        onChange={(e) =>{
                          console.log("vaue:", e.target.value);
                          setSelectedParentId(e.target.value);
                          try {
                            const productPrice = installmentOptions.find(item => String(item.id) === String(e.target.value))?.splitTotalPrice;
                            setSplitTotalPrice(productPrice);
                          } catch (error) {
                            setSplitTotalPrice(0);
                          }
                        } }
                      >
                        {installmentOptions.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.productName}
                          </option>
                        ))}
                      </select>
                    ) : (
                      null
                    )}
                    <input
                        type="text"
                        className="js-input-mask form-control customInput"
                        name="name"
                        id="nameLabel"
                        placeholder="상품 이름을 입력해 주세요."
                        aria-label="상품 이름을 입력해 주세요."
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                  </div>
                </Col>
              </Row>
              {/* 분할 결제 여부 */}
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex align-items-center">
                  <label className="me-3 labelText">분할 결제 여부</label>
                  <input
                    type="checkbox"
                    name="installment"
                    // checked={installment}
                    onChange={(e) => {
                      console.log("e.target.checked", e.target.checked);
                      setInstallment(e.target.checked)
                      setSelectedParentId(0);
                      setSplitTotalPrice(0);
                      console.log("installment", installment);
                    }}
                    
                    aria-label="분할 결제 여부 선택"
                  />
                </Col>
              </Row>
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      종류
                    </label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      {type === "" ? (
                        <option value="">선택해주세요.</option>
                      ) : null}
                      <option value="subscribe">기간제</option>
                      <option value="joinFee">가입비</option>
                      <option value="marriageFee">성혼사례비</option>
                      <option value="purchaseItem">추가매칭권</option>
                    </select>
                  </div>
                </Col>
              </Row>
              {type === "subscribe" ? (
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label htmlFor={`statusLabel`} className="labelText">
                        기간
                      </label>
                      <input
                        type="number"
                        className="js-input-mask form-control customInput"
                        name="name"
                        id="nameLabel"
                        placeholder="기간을 입력해 주세요."
                        aria-label="기간을 입력해 주세요."
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              {type === "joinFee" || type === "purchaseItem" ? (
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label htmlFor={`statusLabel`} className="labelText">
                        매칭권 개수
                      </label>
                      <input
                        type="number"
                        className="js-input-mask form-control customInput"
                        name="name"
                        id="nameLabel"
                        placeholder="매칭권 개수를 입력해 주세요."
                        aria-label="매칭권 개수를 입력해 주세요."
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      가격
                    </label>
                    <input
                      type="number"
                      className="js-input-mask form-control customInput"
                      name="name"
                      id="nameLabel"
                      placeholder="가격을 입력해 주세요."
                      aria-label="가격을 입력해 주세요."
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              {installment ? (  
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label htmlFor={`splitTotalPrice`} className="labelText">
                        전체가격
                      </label>
                      <input
                        type="number"
                        className="js-input-mask form-control customInput"
                        name="splitTotalPrice"
                        id="splitTotalPrice"
                        placeholder="가격을 입력해 주세요."
                        aria-label="가격을 입력해 주세요."
                        value={splitTotalPrice}
                        onChange={(e) => {
                          
                          setSplitTotalPrice(e.target.value)
                        } }
                      />
                    </div>
                  </Col>
                </Row>
                ) : null
              }
              
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label className="labelText" for="marketing_agree">
                      설명
                    </label>
                    <textarea
                      className="js-input-mask form-control customInput"
                      value={desc}
                      placeholder={`설명을 입력해 주세요.`}
                      aria-label={`설명을 입력해 주세요.`}
                      onChange={(e) => setDesc(e.target.value)}
                      rows={5}
                      style={{ resize: "none" }}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-3">
                <Col className="text-end">
                  <Button
                    id="masterAccesBtn"
                    color="danger"
                    style={{ marginRight: "10px" }}
                    onClick={() => onClose()}
                  >
                    닫기
                  </Button>
                  <Button
                    id="masterAccesBtn"
                    color="primary"
                    onClick={() => callApi()}
                  >
                    보내기
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default CashPostModal;
