import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { useParams } from "react-router-dom";

import { requestGet } from "../../apis/request";
import CashPostModal from "./Modal/CashPostModal";

function CashPost({ id, handleChange, onClickSendPurchase }) {
  const [data, setData] = useState([]);
  const [value, setValue] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const callApi = async () => {
    const response = await requestGet(`/api/product/list/admin`);
    setData(response.data);
  };

  useEffect(() => {
    callApi();
  }, []);

  return (
    <React.Fragment>
      <h3 className="MainText pt-3">상품 알림톡 보내기</h3>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom" />
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div>
            <label className="labelText">상품 목록</label>
            <select
              name="purchaseSelect"
              className="form-select customInput"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              style={{ maxWidth: "20rem" }}
            >
              {value === "" ? <option value="">선택해주세요.</option> : null}
              {data?.map((item, i) => {
                return (
                  <option value={item.id} key={i}>
                    {`상품 이름 : ${item.name},
                    가격 : ${String(item.price).replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )}`}
                  </option>
                );
              })}
            </select>
          </div>
        </Col>
      </Row>
      <Row className="pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div>
            <Button
              onClick={() => setModalOpen(true)}
              color="primary"
              style={{ marginTop: "6px", marginRight: "10px" }}
            >
              수기입력
            </Button>
            <Button
              onClick={() => onClickSendPurchase(value)}
              color="primary"
              style={{ marginTop: "6px" }}
            >
              보내기
            </Button>
          </div>
        </Col>
      </Row>
      {modalOpen ? (
        <CashPostModal
          setModal={setModalOpen}
          onClickSendPurchase={onClickSendPurchase}
          userId={id}
        />
      ) : null}
    </React.Fragment>
  );
}

export default CashPost;
