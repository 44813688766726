import PropTypes from "prop-types";
import React, { useEffect } from 'react';

import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import { useHistory } from 'react-router-dom';
// Import scss
import "./assets/scss/theme.scss";




const App = (props) => {
  const history = useHistory();
    useEffect(() => {
        const currentVersion = 2;
        const userToken = localStorage.getItem('loginUserName'); // 확인할 키를 'userToken'으로 가정
        const excludedPaths = ['/login', '/logout'];
        if (!userToken && !excludedPaths.includes(history.location.pathname)) {
            history.push('/login'); // 로그인 페이지로 리디렉션
            window.location.reload(true);
        }
        const lastVersion = localStorage.getItem('lastVersion');
        if (!lastVersion || lastVersion < currentVersion) {
          localStorage.setItem('lastVersion', currentVersion);
          window.location.reload(true);
        }
        
    }, [props]);


  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
