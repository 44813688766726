import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef,useState } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";

import { resetAllLists } from "../../store/zustand/resetHelper";
//i18n
import { withTranslation } from "react-i18next";

import { ReactComponent as Arrow } from "../../assets/icon/custom/arrowleftright.svg";
import { ReactComponent as Calendar } from "../../assets/icon/custom/calendarclose.svg";
import { ReactComponent as Chart } from "../../assets/icon/custom/chart.svg";
import { ReactComponent as Contacts } from "../../assets/icon/custom/contacts.svg";
import { ReactComponent as Heart } from "../../assets/icon/custom/heart.svg";
import { ReactComponent as Hearts } from "../../assets/icon/custom/hearts.svg";
import { ReactComponent as Todo } from "../../assets/icon/custom/todo.svg";
import { ReactComponent as User } from "../../assets/icon/custom/user.svg";
import { ReactComponent as Userheart } from "../../assets/icon/custom/userheart.svg";
import { ReactComponent as Userreceived } from "../../assets/icon/custom/userreceived.svg";
import { ReactComponent as Wallet } from "../../assets/icon/custom/wallet.svg";
import { ReactComponent as UserMinus } from "../../assets/icon/svgs/solid/user-minus.svg";
import { ReactComponent as Coins } from "../../assets/icon/svgs/solid/coins.svg";
import { ReactComponent as Comments } from "../../assets/icon/svgs/solid/comments.svg";
import { ReactComponent as CheckList } from "../../assets/icon/svgs/solid/list-check.svg";
import { ReactComponent as PenCircle } from "../../assets/icon/svgs/solid/pen-circle.svg";
import { ReactComponent as Bomb } from "../../assets/icon/svgs/solid/bomb.svg";



const SidebarContentcopy = (props) => {
  const [managerType, setManagerType] = useState(0);
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    let userType = localStorage.getItem("loginUserType");
    if(userType && userType === 'manager'){
      setManagerType(1);
    } else {
      setManagerType(0);
    }
    // setManagerType(0);

    const pathName = props.location.pathname;
    
    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  const scrollElement = (item) => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  };

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            { managerType === 1 ? null : (
              <li>
              <a href="/consult/list" onClick={() => resetAllLists()}>
                <i>
                  <span>
                    <Todo />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("상담요청 목록")}</span>
              </a>
            </li>
            ) 
            }
            
            <li>
              <a href="/consult/member/list" onClick={() => resetAllLists()}>
                <i>
                  <span>
                    <Contacts fill="#97D6FF" />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("상담회원 목록")}</span>
              </a>
            </li>
            <li>
              <a href="/consult/request/list" onClick={() => resetAllLists()}>
                <i>
                  <span>
                    <Userreceived fill="#97D6FF" />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("승인대기 회원 목록")}</span>
              </a>
            </li>
            <li>
              <a href="/member" onClick={() => resetAllLists()}>
                <i>
                  <span>
                    <User fill="#97D6FF" />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("회원 목록")}</span>
              </a>
            </li>
            <li>
              <a href="/member/delete/list" onClick={() => resetAllLists()}>
                <i>
                  <span>
                    <UserMinus fill="#97D6FF" />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("탈퇴 회원 목록")}</span>
              </a>
            </li>
            <li>
              <a href="/intro/list" onClick={() => resetAllLists()}>
                <i>
                  <span>
                    <Arrow fill="#97D6FF" />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("소개 목록")}</span>
              </a>
            </li>
            <li>
              <a href="/like/list" onClick={() => resetAllLists()}>
                <i>
                  <span>
                    <Userheart fill="#97D6FF" />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("호감 목록")}</span>
              </a>
            </li>
            <li>
              <a href="/like/match/list" onClick={() => resetAllLists()}>
                <i>
                  <span>
                    <Hearts fill="#97D6FF" />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("맞호감 목록")}</span>
              </a>
            </li>
            <li>
              <a href="/like/match/user/list" onClick={() => resetAllLists()}>
                <i>
                  <span>
                    <CheckList fill="#97D6FF" />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("매칭 연결 목록")}</span>
              </a>
            </li>
            <li>
              <a href="/schedule" onClick={() => resetAllLists()}>
                <i>
                  <span>
                    <Hearts fill="#97D6FF" />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("매칭 연결 스케쥴")}</span>
              </a>
            </li>
            {/* <li>
              <a
                href="/like/match/message/list"
                onClick={() => resetAllLists()}
              >
                <i>
                  <span>
                    <Comments fill="#97D6FF" />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("매칭 연결 대화 목록")}</span>
              </a>
            </li> */}
            <li>
              <a href="/cash/history" onClick={() => resetAllLists()}>
                <i>
                  <span>
                    <Wallet fill="#97D6FF" />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("결제 목록")}</span>
              </a>
            </li>
            <li>
              <a href="/incentive/list" onClick={() => resetAllLists()}>
                <i>
                  <span>
                    <Coins fill="#97D6FF" />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("인센티브 목록")}</span>
              </a>
            </li>
            <li>
              <a href="/like/history" onClick={() => resetAllLists()}>
                <i>
                  <span>
                    <Heart fill="#97D6FF" />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("매칭권 사용 목록")}</span>
              </a>
            </li>
            <li>
              <a href="/alarm/list" onClick={() => resetAllLists()}>
                <i>
                  <span>
                    <Comments fill="#97D6FF" />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("알림 발송 목록")}</span>
              </a>
            </li>
            { managerType === 1 ? null : null}
            { managerType === 1 ? null :(<li>
              <a href="/disabled/schedule" onClick={() => resetAllLists()}>
                <i>
                  <span>
                    <Calendar fill="#97D6FF" />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("불가능 스케쥴 관리")}</span>
              </a>
            </li>)}
            { false ? null :(
            <li>
            <a href="/total/list" onClick={() => resetAllLists()}>
              <i>
                <span>
                  <Chart fill="#97D6FF" />
                </span>
              </i>
              <span key="t-dashboard">{props.t("통계 목록")}</span>
            </a>
          </li>
            )}
            
            { managerType === 1 ? null :(
            <li>
              <a href="/coupleReview/list" onClick={() => resetAllLists()}>
                <i>
                  <span>
                    <PenCircle fill="#97D6FF" />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("커플 후기")}</span>
              </a>
            </li>
            )}
            { managerType === 1 ? null :(
            <li>
              <a href="/partyReview/list" onClick={() => resetAllLists()}>
                <i>
                  <span>
                    <Bomb fill="#97D6FF" />
                  </span>
                </i>
                <span key="t-dashboard">{props.t("파티 후기")}</span>
              </a>
            </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContentcopy.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContentcopy));
